/* eslint-disable react/display-name */
import React from 'react';
import useGallery from '../hooks/use-gallery';
import useGalleryThumbnails from '../hooks/use-gallery thumbnails';
import Gallery2 from '../components/gallery2';

export default () => {
  const gallery = useGallery();
  const galleryThumbnails = useGalleryThumbnails();

  const images = [];

  gallery.map(image => {
    let srcSetObject = {
      original: image.images,
      srcSet: image.images,
    };
    return images.push(srcSetObject);
  });

  for (let i = 0; i < galleryThumbnails.length; i++) {
    images[i].thumbnail = galleryThumbnails[i].images;
  }

  return (
    <>
      <Gallery2 images={images} />
    </>
  );
};
