import { graphql, useStaticQuery } from 'gatsby';

const useGallery = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLindseyGalleryImages {
        nodes {
          lindseyGalleryImages {
            title
            fluid(quality: 90, maxHeight: 1200, maxWidth: 1400) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `);

  return data.allContentfulLindseyGalleryImages.nodes[0].lindseyGalleryImages.map(
    (gallery) => ({
      title: gallery.title,
      images: gallery.fluid.srcSet,
      imagesForCredits: gallery.fluid,
    }),
  );
};

export default useGallery;
