import { graphql, useStaticQuery } from 'gatsby';

const useGalleryThumbnails = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLindseyGalleryImages {
        nodes {
          lindseyGalleryImages {
            fixed(width: 250, height: 150) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  `);

  return data.allContentfulLindseyGalleryImages.nodes[0].lindseyGalleryImages.map(
    (gallery) => ({
      images: gallery.fixed.src,
    }),
  );
};

export default useGalleryThumbnails;
